@import "../../style/custonVariable";
.container{
    padding: 15px;
    table{
        background: $color-white;
        .actionIcon{
            color: #7266ba;
            font-size: 18px;
        }
    }
}
.modalContent{
    border: none;
    width: 786px;
    .modalHeader{
        background-color: #7266ba;
        text-align: center;
        color: $color-white;
        display: block;
        button{
            padding: 1rem 1rem;
            margin: -38px -10px -25px auto;
            color: $color-white;
            font-size: large;
            span{
                font-size: 28px;
            }
        }
    }
    .invoiceBody{
        padding: 15px;
        .printButton{
            text-align: right;
        }
        .printArea{
            border: 1px solid #dee5e7;
        }
        .bordered{
            td,th{
                border: 1px solid #ddd;
                vertical-align: middle !important;
            }
        }
        .tableBottom{
            tr{
                border-bottom: 1px solid #c8c8c8;
            }
            td{
                padding: 5px;
            }
        }
    }
}

.filterSection {
    ._3vt7_Mh4hRCFbp__dFqBCI input {
        font-size: 15px;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .dark_mode_DateRangePicker {
        background: #14243A !important;
        cursor: pointer;
        padding: 5px;
        border: 1px solid #ccc;
        min-width: 10%;
        max-width: max-content;
        display: inline-block;
        margin-right: 5px;
        font-size: 15px;

        @media (max-width: 767px) {
            margin-bottom: 5px;
            width: 100%;
            max-width: none;
        }
    }

    @media (max-width: 767px) {
        display: block;
    }

    .filterButton {
        display: inline;

        @media (max-width: 767px) {
            display: block;
        }

        .padding5 {
            padding: 10px;
            margin-right: 3px;
        }

        @media (max-width:600px) {
            margin-right: 8px;
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }

    ._2iA8p44d0WZ-WqRBGcAuEV {
        margin-bottom: 10px !important;
    }

    .exportButton {
        display: inline;
        float: right;

        button {
            background-color: $color-primary;
            height: $input-height;
            border-color: $color-primary;
            border-radius: 0.1875rem;
            margin-right: 0.333em;
            margin-bottom: 0.333em;
            margin-top: 10px;
            ;

            @media (max-width:600px) {
                margin-right: 8px;
                margin-top: 10px;
                margin-bottom: 5px;
            }

            i {
                text-align: center;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px 0 0 2px;
                position: relative;
                float: left;
                width: 30px;
                height: 30px;
                margin: -6px -12px;
                margin-right: -12px;
                margin-right: -12px;
                margin-right: 3px;
                line-height: 30px;
            }
        }
    }
}
/****************************************** dark mode *****************************************/ 

.dark_mode_container{
    padding: 15px;
    table{
        //background: $color-white;
        .actionIcon{
            color: #7266ba;
            font-size: 18px;
        }
    }
}
.modalContent{
    border: none;
    width: 786px;
    .dark_mode_modalHeader{
        background-color: #7266ba;
        text-align: center;
        //color: $color-white;
        display: block;
        button{
            padding: 1rem 1rem;
            margin: -38px -10px -25px auto;
        }
    }
    .invoiceBody{
        padding: 15px;
        .printButton{
            text-align: right;
        }
        .printArea{
            border: 1px solid #dee5e7;
        }
        .bordered{
            td,th{
                border: 1px solid #ddd;
                vertical-align: middle !important;
            }
        }
        .tableBottom{
            tr{
                border-bottom: 1px solid #c8c8c8;
            }
            td{
                padding: 5px;
            }
        }
    }
}

/************************************** end of dark mode **************************************/